import { useEffect, useState } from 'react';

import {
  CheckoutStep,
  LoggedIn,
  PaymentFailureModalWeb
} from '@/components/atomic/atoms';
import {
  CheckoutEventContactInfoOrganism,
  CheckoutHeaderOrganism,
  CheckOutOrderSummaryOrganism,
  LoginWithOTPOrganism
} from '@/components/atomic/organism';
import phoneCountryCodes from '@/config/phoneCountryCodes';

const CheckoutDesktopLayoutTemplate = ({
  currentStep,
  isLoggedIn,
  setCurrentStep,
  setIsLoggedIn,
  setStepReached,
  status,
  stepReached
}) => {
  const [otpSent, setOtpSent] = useState(false);
  const [OTP, setOTP] = useState('');
  const [countryCode, setCountryCode] = useState(phoneCountryCodes.AE.code);
  const [mobile, setMobile] = useState('');
  const [showPaymentFailed, setShowpaymentFailed] = useState(status);

  useEffect(() => {
    if (status) {
      setCurrentStep(3);
      setStepReached(3);
      setShowpaymentFailed(status);
    }
  }, [status]);

  return (
    <>
      <CheckoutHeaderOrganism heading='Checkout' />
      <div className='container mx-auto px-5 md:px-0 lg:px-0 xl-1:px-0 2xl:px-0'>
        <div className='w-full flex md:justify-center'>
          <div className='w-full md:w-3/5 xl:max-w-182.5 md:me-7 xl:me-10'>
            <CheckoutStep
              {...{
                currentStep,
                heading: '1. Login / Signup',
                setCurrentStep,
                stepReached,
                thisStep: 1
              }}
            >
              <div className='max-w-128 mx-auto'>
                {isLoggedIn ? (
                  <LoggedIn
                    {...{
                      currentStep,
                      setCurrentStep,
                      setStepReached
                    }}
                  />
                ) : (
                  <LoginWithOTPOrganism
                    {...{
                      countryCode,
                      mobile,
                      OTP,
                      otpSent,
                      setCountryCode,
                      setIsLoggedIn,
                      setMobile,
                      setOTP,
                      setOtpSent
                    }}
                  />
                )}
              </div>
            </CheckoutStep>
            <CheckoutStep
              {...{
                currentStep,
                heading: '2. Event Information',
                isLastTab: true,
                setCurrentStep,
                stepReached,
                thisStep: 2
              }}
            >
              <CheckoutEventContactInfoOrganism
                {...{
                  currentStep,
                  setCurrentStep,
                  setStepReached,
                  stepReached
                }}
              />
            </CheckoutStep>
          </div>
          <CheckOutOrderSummaryOrganism />
        </div>
        {showPaymentFailed && (
          <PaymentFailureModalWeb
            {...{
              handlePaymentFailed: () => {
                setShowpaymentFailed('');
              },
              handlePaymentFailedModalClose: () => {
                setShowpaymentFailed('');
              },
              paymentFailMessage: 'Payment didn’t go through, Please retry.',
              setCloseModal: setShowpaymentFailed
            }}
          />
        )}
      </div>
    </>
  );
};

export default CheckoutDesktopLayoutTemplate;

CheckoutDesktopLayoutTemplate.defaultProps = {
  fetchedUserDetails: false
};
