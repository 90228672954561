import { NoItemFound, SectionHeader } from '@/components/atomic/atoms';
import { CartCard } from '@/components/atomic/molecules';
import {
  CartListOrganism,
  UserEventCartDetailsSideBarOrganism,
  UserEventProfileCustomerOrganism
} from '@/components/atomic/organism';
import { CART_LIST_TYPE, CART_STATUS } from '@/config/common';
import { ROUTE_CONFIG, getPageURL } from '@/services/hostPortal.service';

const onCartCardClick = ({ id, router }) => {
  router.push(
    getPageURL({
      pageName: ROUTE_CONFIG.HOST_CART.label,
      pathParams: {
        cartId: id
      }
    })
  );
};

const filterActiveCart = ({ status, isArchived }) =>
  [CART_STATUS.DRAFT, CART_STATUS.COLLABORATE].includes(status) && !isArchived;
const filterArchivedCart = ({ isArchived }) => isArchived;
const filterOrderedCart = ({ status }) => [CART_STATUS.ORDER].includes(status);

const CartList = ({ cartCardList, router }) =>
  cartCardList?.length > 0 ? (
    cartCardList.map(
      ({ id, cartName, cartNumber, cartOrder, createdAt, opsUser, status }) => (
        <div
          id='cartCard'
          key={cartNumber}
          onClick={() => onCartCardClick({ id, router })}
        >
          <CartCard
            key={id}
            {...{
              cartName,
              cartNumber,
              cartOrder,
              createdAt,
              opsUser,
              status
            }}
          />
        </div>
      )
    )
  ) : (
    <NoItemFound />
  );

const UserEventsDetailTemplate = ({
  authUser,
  cartCardList,
  displayUserEventProfile,
  isMobile,
  router,
  setShowUserEventProfile,
  showUserEventProfile,
  userEvent
}) => {
  const activeEventCarts = cartCardList.filter(filterActiveCart);
  const archivedCarts = cartCardList.filter(filterArchivedCart);
  const orderedCarts = cartCardList.filter(filterOrderedCart);
  return (
    <div className='flex flex-col md:flex-row bg-neutral md:min-h-screen'>
      {userEvent && (
        <UserEventCartDetailsSideBarOrganism
          {...{
            displayUserEventProfile,
            isMobile,
            userEvent
          }}
        />
      )}

      <div className='flex-1 px-5 md:pl-5 md:pr-24 relative'>
        <div
          className={` ${
            showUserEventProfile && 'bg-white my-6 px-6 pt-3 pb-10'
          }`}
        >
          {showUserEventProfile && (
            <UserEventProfileCustomerOrganism
              {...{
                authUser,
                setShowUserEventProfile,
                userEvent
              }}
            />
          )}
        </div>
        <SectionHeader
          {...{
            heading: 'Event Cart List',
            headingStyle:
              'text-base md:text-2xl font-medium font-Montserrat flex gap-22 self-center mt-5'
          }}
        />
        <CartList
          {...{
            cartCardList: activeEventCarts,
            router
          }}
        />
        {orderedCarts.length > 0 && (
          <CartListOrganism
            {...{
              cartCardList: orderedCarts,
              isMobile,
              router,
              showControlToCollapseList: true,
              title: 'Event Order List'
            }}
          />
        )}

        {archivedCarts.length > 0 && (
          <CartListOrganism
            {...{
              cartCardList: archivedCarts,
              cartListType: CART_LIST_TYPE.ARCHIVE,
              isMobile,
              router,
              showControlToCollapseList: true,
              title: 'Archived Carts'
            }}
          />
        )}
      </div>
    </div>
  );
};

export default UserEventsDetailTemplate;
