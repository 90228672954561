import { RenderQuoteSubmittedNotification } from '@/components/atomic/atoms';
import {
  CartItemRowMobileOrganism,
  CartItemRowWebOrganism,
  PricedItemListOrganism,
  RequestPendingItemListOrganism,
  RequestedItemListOrganism,
  UnavailableItemListOrganism
} from '@/components/atomic/organism';
import {
  CART_ITEM_STATUS,
  PRODUCT_STATUS,
  PRODUCT_TYPE
} from '@/config/common';
import { itemMediaListMapper } from '@/helpers/carousel';
import { GetUiDeviceSurface } from '@/helpers/screenResolutionHelper';
import { getFormattedDate } from '@/lib/time';
import { filterItemsByCartItemGroupType } from '@/services/hostCart.service';

const getItemListGroups = (items) => ({
  checkoutReadyItems: filterItemsByCartItemGroupType({
    items,
    type: 'CHECKOUT_READY'
  }),
  priceAwaitedCartItemList: filterItemsByCartItemGroupType({
    items,
    type: 'REQUEST_PENDING'
  }),
  requestedItems: filterItemsByCartItemGroupType({
    items,
    type: 'REQUESTED'
  }),
  unavailableCartItemList: filterItemsByCartItemGroupType({
    items,
    type: 'UNAVAILABLE'
  })
});

const canUpdateItemQuantity = ({ status, productStatus, type }) =>
  (type === PRODUCT_TYPE.QUOTE
    ? status === CART_ITEM_STATUS.REQUEST_PENDING
    : status !== CART_ITEM_STATUS.REQUESTED) &&
  productStatus !== PRODUCT_STATUS.UNPUBLISHED;

const ItemRowMap = {
  mobile: CartItemRowMobileOrganism,
  web: CartItemRowWebOrganism
};

const isSpinnerInputReadOnlyMapper = (item) => ({
  ...item,
  isSpinnerInputReadOnly: canUpdateItemQuantity({
    productStatus: item.product.status,
    status: item.status,
    type: item.product.type
  })
});

const CartItemListTemplate = ({
  cartItems: itemListRaw,
  cartNumber,
  checkoutEventList,
  deleteItem,
  id: cartId,
  isCartEditable,
  isRequestForQuote,
  setIsRequestForQuote,
  setShowQuoteRequestSubmittedAlert,
  showQuoteRequestSubmittedAlert,
  updateCartItemDetail,
  updateRequestForQuoteCartItems,
  userCart,
  userEventSummary
}) => {
  const itemList = itemListRaw
    .map(isSpinnerInputReadOnlyMapper)
    .map(({ cartItemMedia: additionalMediaList, ...item }) =>
      itemMediaListMapper({
        ...item,
        additionalMediaList
      })
    );
  const {
    checkoutReadyItems,
    priceAwaitedCartItemList,
    requestedItems,
    unavailableCartItemList
  } = getItemListGroups(itemList);

  const surface = GetUiDeviceSurface();
  const commonCartItemProps = {
    CartItemRow: ItemRowMap[surface],
    cartItemTitleClass: '',
    deleteItem,
    isCartEditable,
    priceLabelClass: '',
    updateCartItemDetail
  };

  const onClickRequestForQuote = async (data) => {
    const { name: eventTypeName } = checkoutEventList.find(
      ({ id }) => id === data.checkoutEventId
    );
    const productNames = priceAwaitedCartItemList.map(
      ({ product: { name } }) => name
    );
    const cartItemIds = priceAwaitedCartItemList.map(({ id }) => id);
    const updatedData = {
      ...data,
      cartId,
      cartItemIds,
      cartNumber,
      eventDate: getFormattedDate({
        date: data.eventDate,
        format: 'MM/DD/YYYY'
      }),
      eventTypeName,
      productNames
    };
    updateRequestForQuoteCartItems({ data: updatedData });
  };

  return (
    <div className='flex-1 grid gap-y-4 md:self-baseline'>
      {checkoutReadyItems.length > 0 && (
        <PricedItemListOrganism
          {...{
            itemList: checkoutReadyItems,
            ...commonCartItemProps
          }}
        />
      )}
      {requestedItems.length > 0 && (
        <RequestedItemListOrganism
          {...{ itemList: requestedItems, ...commonCartItemProps }}
        />
      )}
      {priceAwaitedCartItemList.length > 0 && (
        <RequestPendingItemListOrganism
          {...{
            ...commonCartItemProps,
            checkoutEventList,
            isRequestForQuote,
            itemList: priceAwaitedCartItemList,
            onClickRequestForQuote,
            setIsRequestForQuote,
            userCart,
            userEventSummary
          }}
        />
      )}
      {unavailableCartItemList.length > 0 && (
        <UnavailableItemListOrganism
          {...{
            ...commonCartItemProps,
            cartItemTitleClass: 'text-error-base',
            itemList: unavailableCartItemList,
            priceLabelClass: 'text-error-base'
          }}
        />
      )}
      {isCartEditable && showQuoteRequestSubmittedAlert && (
        <div className='md:absolute md:bottom-0 md:left-1 w-full md:px-6'>
          <RenderQuoteSubmittedNotification
            alertMessage='Response submitted we will get back to you soon.'
            handleClose={setShowQuoteRequestSubmittedAlert}
          />
        </div>
      )}
    </div>
  );
};

export default CartItemListTemplate;
