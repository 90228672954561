import { useRef } from 'react';

import { CTA } from '@/components/atomic/atoms';
import {
  EditPromoCode,
  ExternalNote,
  InputPromoCode
} from '@/components/atomic/molecules';
import { Text, TextKeyValuePair } from '@/components/atomic/nuclei';
import PriceSummaryOrganism from '@/components/atomic/organism/hostCart/PriceSummaryOrganism';
import { GetUiDeviceSurface } from '@/helpers/screenResolutionHelper';
import { parseFormatPriceValueFromAPI } from '@/lib/numberStringUtils';

const CartSummaryTemplateMobile = ({
  code,
  isCartEditable,
  isDefaultCart,
  note,
  onProceedForCheckout,
  orderTotal,
  priceSummaryList,
  proceedToCheckoutAndPaymentDisabled,
  processApplyPromoCode,
  processRemovePromoCode,
  promoCodeDiscountAmount,
  promoCodeError,
  setPromoCodeError
}) => {
  const mobileSummaryRef = useRef(null);

  return (
    <div>
      {note && (
        <div className='border border-platinum rounded-2.5 p-3 mb-4'>
          <ExternalNote {...{ note }} />
        </div>
      )}

      <div className='w-full md:w-95 border border-platinum rounded-2.5 p-3 mb-12'>
        {isCartEditable && (
          <>
            <Text
              {...{ content: 'Promo Code', className: 'font-medium mb-4' }}
            />
            {promoCodeDiscountAmount ? (
              <EditPromoCode
                {...{
                  promoCode: code,
                  promoCodeAmount: parseFormatPriceValueFromAPI(
                    promoCodeDiscountAmount
                  ),
                  removePromoCode: processRemovePromoCode
                }}
              />
            ) : (
              <InputPromoCode
                {...{
                  derivedValues: { promoCode: { code } },
                  processApplyPromoCode,
                  promoCodeError,
                  setPromoCodeError
                }}
              />
            )}
            <div
              ref={mobileSummaryRef}
              className='my-6 border-t border-platinum'
            ></div>
          </>
        )}
        <PriceSummaryOrganism {...{ priceSummaryList }} />
      </div>
      <div className='flex w-full justify-between bg-white px-6 fixed z-20 bottom-0 left-0 py-3 border-t'>
        <div className='flex flex-col gap-1 w-1/2 -mt-2'>
          <a
            onClick={() =>
              mobileSummaryRef?.current?.scrollIntoView({ behavior: 'smooth' })
            }
          >
            <TextKeyValuePair
              {...{
                label: `AED ${parseFormatPriceValueFromAPI(orderTotal)}`,
                labelClass: 'text-sm font-semibold',
                value: 'View price details',
                valueClassName:
                  'text-xs font-medium text-light-purple cursor-pointer'
              }}
            />
          </a>
        </div>
        <div className='w-1/2'>
          <CTA
            {...{
              buttonColor: 'bg-brand-gradient text-white',
              children: isDefaultCart ? 'Checkout' : 'Proceed To Payment',
              className: 'w-full py-3 text-sm font-medium',
              disabled: proceedToCheckoutAndPaymentDisabled,
              onClick: onProceedForCheckout
            }}
          />
        </div>
      </div>
    </div>
  );
};

const CartSummaryTemplateWeb = ({
  code,
  isCartEditable,
  isDefaultCart,
  note,
  onProceedForCheckout,
  priceSummaryList,
  proceedToCheckoutAndPaymentDisabled,
  processApplyPromoCode,
  processRemovePromoCode,
  promoCodeDiscountAmount,
  promoCodeError,
  setPromoCodeError
}) => (
  <div className='w-full md:w-95 border border-platinum rounded-2.5 p-6 self-start min-h-0'>
    {isCartEditable && (
      <>
        <Text {...{ content: 'Promo Code', className: 'font-medium mb-4' }} />
        {promoCodeDiscountAmount ? (
          <EditPromoCode
            {...{
              promoCode: code,
              promoCodeAmount: parseFormatPriceValueFromAPI(
                promoCodeDiscountAmount
              ),
              removePromoCode: processRemovePromoCode
            }}
          />
        ) : (
          <InputPromoCode
            {...{
              derivedValues: { promoCode: { code } },
              processApplyPromoCode,
              promoCodeError,
              setPromoCodeError
            }}
          />
        )}
        <div className='my-6 border-t border-platinum'></div>
      </>
    )}
    <PriceSummaryOrganism {...{ priceSummaryList }} />
    <div className='my-5'></div>
    <CTA
      {...{
        buttonColor: 'bg-brand-gradient p-10 text-white',
        children: isDefaultCart ? 'Checkout' : 'Proceed To Payment',
        className: 'w-full py-3',
        disabled: proceedToCheckoutAndPaymentDisabled,
        onClick: onProceedForCheckout
      }}
    />
    <ExternalNote {...{ note }} />
  </div>
);

const CartSummaryMap = {
  mobile: CartSummaryTemplateMobile,
  web: CartSummaryTemplateWeb
};

const CartSummaryTemplate = ({
  additionalChargeAmount,
  deliveryChargeAmount,
  isCartEditable = true,
  isDefaultCart,
  itemsForPriceSummary,
  listedDiscountAmount,
  listedPriceAmount,
  lumpSumDiscount,
  nonTaxableCharge,
  note,
  onProceedForCheckout,
  orderTotal,
  preVatAmount,
  processApplyPromoCode,
  processRemovePromoCode,
  promoCode,
  promoCodeDiscountAmount,
  promoCodeError,
  sellingPriceAmount,
  setPromoCodeError,
  unavailableCartItem,
  vatAmount
}) => {
  const surface = GetUiDeviceSurface();
  const CartSummary = CartSummaryMap[surface];

  const proceedToCheckoutAndPaymentDisabled =
    !itemsForPriceSummary.length || Boolean(unavailableCartItem.length);

  const priceSummaryList = [
    {
      dbName: 'listedPriceAmount',
      isVisible: Boolean(listedDiscountAmount),
      title: 'Sub Total (AED)',
      value: parseFormatPriceValueFromAPI(listedPriceAmount)
    },
    {
      dbName: 'listedDiscountAmount',
      isVisible: Boolean(listedDiscountAmount),
      title: 'Item Discounts (AED)',
      value: `- ${parseFormatPriceValueFromAPI(listedDiscountAmount)}`,
      valueClass: 'text-green font-medium'
    },
    {
      dbName: 'sellingPriceAmount',
      title: 'Item Total (AED)',
      value: parseFormatPriceValueFromAPI(sellingPriceAmount)
    },
    {
      dbName: 'deliveryChargeAmount',
      title: 'Delivery Charges (AED)',
      value: parseFormatPriceValueFromAPI(deliveryChargeAmount)
    },
    {
      dbName: 'additionalChargeAmount',
      title: 'Additional Charges (AED)',
      value: parseFormatPriceValueFromAPI(additionalChargeAmount)
    },
    {
      dbName: 'promoCodeDiscountAmount',
      isVisible: Boolean(promoCodeDiscountAmount),
      subTitle: promoCode?.code || '',
      subTitleClass: 'text-green font-medium',
      title: 'Promo',
      value: `-${parseFormatPriceValueFromAPI(promoCodeDiscountAmount)}`,
      valueClass: 'text-green font-medium'
    },
    {
      dbName: 'bulkDiscount',
      isVisible: Boolean(lumpSumDiscount),
      title: 'Bulk Discount',
      value: `- ${parseFormatPriceValueFromAPI(lumpSumDiscount)}`,
      valueClass: 'text-green font-medium'
    },
    {
      dbName: 'preVatAmount',
      title: 'Pre-VAT Total (AED)',
      value: parseFormatPriceValueFromAPI(preVatAmount)
    },
    {
      dbName: 'vatAmount',
      title: 'VAT (5%)',
      value: parseFormatPriceValueFromAPI(vatAmount)
    },
    {
      dbName: 'nonTaxableCharge',
      title: 'Other Charges (non-taxable)',
      value: parseFormatPriceValueFromAPI(nonTaxableCharge),
      show: nonTaxableCharge > 0
    },
    {
      dbName: 'orderTotal',
      title: 'Grand Total (AED)',
      value: parseFormatPriceValueFromAPI(orderTotal)
    }
  ];
  return (
    <CartSummary
      {...{
        code: promoCode?.code,
        isCartEditable,
        isDefaultCart,
        note,
        onProceedForCheckout,
        orderTotal,
        priceSummaryList,
        proceedToCheckoutAndPaymentDisabled,
        processApplyPromoCode,
        processRemovePromoCode,
        promoCodeDiscountAmount,
        promoCodeError,
        setPromoCodeError
      }}
    />
  );
};

export default CartSummaryTemplate;
