import Image from 'next/image';
import { useState } from 'react';
import { BrowserView, isMobile } from 'react-device-detect';
import Masonry from 'react-masonry-css';

import { ImageCarouselModal, SectionHeader } from '@/components/atomic/atoms';
import {
  CheckoutHeaderOrganism,
  HeaderOrganism
} from '@/components/atomic/organism';
import { staticMediaStoreBaseURL } from '@/config/common';
import { transformImagesForGridAndCarousel } from '@/helpers/carousel';

const EventGalleryGridTemplate = ({ eventGallery }) => {
  const [showImageModal, setShowimageModal] = useState(false);

  const openImageModal = (image) => {
    const bodyElmStyle = document.getElementsByTagName('body')[0].style;
    bodyElmStyle.removeProperty('height');
    bodyElmStyle.removeProperty('overflow-y');
    setShowimageModal(image);
  };

  const handleMouseHover = (id) => {
    if (
      (id || id === 0) &&
      document.getElementById(`onMouseOverDiv${id}`) &&
      document.getElementById(`image${id}`) &&
      document.getElementById(`event-desc${id}`)
    ) {
      document.getElementById(`onMouseOverDiv${id}`).style.backgroundColor =
        'black';
      document.getElementById(`image${id}`).style.opacity = '0.5';
      document.getElementById(`event-desc${id}`).style.display = 'block';
    }
  };

  const handleMouseLeave = (id) => {
    if (
      (id || id === 0) &&
      document.getElementById(`onMouseOverDiv${id}`) &&
      document.getElementById(`image${id}`) &&
      document.getElementById(`event-desc${id}`)
    ) {
      document.getElementById(`onMouseOverDiv${id}`).style.backgroundColor =
        'none';
      document.getElementById(`image${id}`).style.opacity = '1';
      document.getElementById(`event-desc${id}`).style.display = 'none';
    }
  };

  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 3,
    500: 2
  };

  const eventGalleryImagesTransformed = transformImagesForGridAndCarousel(
    eventGallery?.eventCategory.images || []
  );

  return (
    <div className=''>
      <div className='md:hidden'>
        <CheckoutHeaderOrganism heading='Event Gallery' />
      </div>
      <div className='hidden md:block'>
        <HeaderOrganism heading='Event Gallery' />
      </div>
      <div className='gallery-grid-main-container'>
        <div className='hidden'>
          {' '}
          <SectionHeader heading='Event gallery' />
        </div>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className='my-masonry-grid'
          columnClassName='my-masonry-grid-column'
        >
          {eventGalleryImagesTransformed.map((image, index) => (
            <ImageCard
              key={index}
              {...{
                image,
                isMobile,
                openImageModal,
                handleMouseHover,
                handleMouseLeave
              }}
            />
          ))}
        </Masonry>
        {showImageModal && (
          <ImageCarouselModal
            {...{
              modalImage: showImageModal,
              openImageModal,
              images: eventGalleryImagesTransformed
            }}
          />
        )}
      </div>
    </div>
  );
};

const ImageCard = ({
  image,
  openImageModal,
  handleMouseLeave,
  handleMouseHover
}) => {
  const { id, eventName, organiser } = image;
  const urlSuffix = isMobile
    ? image.eventGalleryMobileThumbnail
    : image.eventGalleryDesktopThumbnail;
  const imageTitle = `${eventName} ${organiser ? ' by' : ''}`;
  return (
    <div
      className='relative cursor-pointer rounded-lg'
      id={`onMouseOverDiv${id}`}
      key={id}
      onClick={() => openImageModal(image)}
      onMouseLeave={() => handleMouseLeave(id)}
      onMouseOver={() => handleMouseHover(id)}
    >
      <Image
        {...{
          alt: 'Home Image Gallery',
          className: 'rounded-lg w-full',
          height: 350,
          id: `image${id}`,
          src: `${staticMediaStoreBaseURL}${urlSuffix}`,
          width: 580
        }}
      />
      <BrowserView>
        <div
          className='absolute bottom-8 left-6 text-white font-medium text-sm md:text-lg leading-7 hidden font-Montserrat'
          id={`event-desc${id}`}
        >
          {imageTitle}
          <br />
          {organiser && <span className='text-xl'>{organiser}</span>}
        </div>
      </BrowserView>
    </div>
  );
};

export default EventGalleryGridTemplate;
