import { useState } from 'react';

import { AlertMessage } from '@/components/atomic/atoms';
import {
  ExternalNotes,
  ItemDetail,
  TermsAndConditions
} from '@/components/atomic/molecules';
import { ItemSummaryCardOrganism } from '@/components/atomic/organism';
import { UNAVAILABLE_CART_ITEM_ALERT_FOR_QUOTE } from '@/config/common';

const ItemList = ({ cartOrderItems }) => {
  const [itemInDetailView, setItemInDetailView] = useState(null);
  return (
    <div>
      <div className='flex flex-col gap-4'>
        {cartOrderItems.map((cartOrderItem, index) => {
          const ItemSummaryDetailView =
            itemInDetailView === cartOrderItem.id
              ? ItemDetail
              : ItemSummaryCardOrganism;
          return (
            <ItemSummaryDetailView
              key={index}
              {...{
                ...cartOrderItem,
                setItemInDetailView
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

const UnAvailableItemList = ({ cartOrderItems }) =>
  cartOrderItems.length > 0 && (
    <div className='border border-red-500 px-3 py-4 md:p-6 rounded-2.5 flex flex-col gap-3'>
      <AlertMessage
        {...{
          message: UNAVAILABLE_CART_ITEM_ALERT_FOR_QUOTE
        }}
      />
      <ItemList {...{ cartOrderItems }} />
    </div>
  );

const ItemListWithNotesAndTermsTemplate = ({
  cartOrderItems,
  externalNotes,
  termsAndConditions,
  unAvailableCartItems = []
}) => (
  <div className='flex flex-col md:w-4/5 gap-4'>
    <ItemList
      {...{
        cartOrderItems
      }}
    />
    <UnAvailableItemList
      {...{
        cartOrderItems: unAvailableCartItems
      }}
    />
    <div className='hidden md:flex flex-col gap-4'>
      <ExternalNotes {...{ externalNotes }} />
      <TermsAndConditions {...{ termsAndConditions }} />
    </div>
  </div>
);

export default ItemListWithNotesAndTermsTemplate;
