import dynamic from 'next/dynamic';
import { Fragment, useState } from 'react';

import { MobileHomeHeader } from '@/components/atomic/atoms';
import {
  AboutSectionMobile,
  BecomePartnerMobile,
  EventGlimpsesMobile,
  FeaturedCategoriesMobile,
  TopBrandsMobile,
  TrendingServices,
  TrustedPartners
} from '@/components/atomic/molecules';
import {
  DefaultLayoutOrganism,
  HaflaBlogsMobileOrganism,
  MobileNavbarOrganism,
  TrendingCollectionOrganism,
  WhyChooseHaflaMobileOrganism
} from '@/components/atomic/organism';

const HowDoesItWork = dynamic(
  () => import('@/components/atomic/molecules/homePage/HowDoesItWork'),
  {
    ssr: false
  }
);

const tempInactive = false;

const MobileHomeTemplate = ({ bannerTranslation, blogPost, collections }) => {
  const [showSearchModal, setShowSearchModal] = useState(false);

  return (
    <Fragment>
      <MobileHomeHeader
        isHomePage={true}
        showSearchModal={showSearchModal}
      />
      <DefaultLayoutOrganism
        paramData={{
          bannerTranslate: bannerTranslation,
          eventCategory: 'home',
          homeScreen: true,
          translate: bannerTranslation
        }}
      >
        <TopBrandsMobile />
        <TrendingServices />
        <FeaturedCategoriesMobile />
        <TrustedPartners />
        <EventGlimpsesMobile />
        <BecomePartnerMobile />
        <TrendingCollectionOrganism {...{ collectionList: collections }} />
        <WhyChooseHaflaMobileOrganism />
        <HaflaBlogsMobileOrganism posts={blogPost} />
        {tempInactive && <HowDoesItWork {...{ isMobile: true }} />}
        <MobileNavbarOrganism
          onPage='home'
          setShowSearchModal={setShowSearchModal}
          showSearchModal={showSearchModal}
        />
        <AboutSectionMobile />
      </DefaultLayoutOrganism>
    </Fragment>
  );
};

export default MobileHomeTemplate;
