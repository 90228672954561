import { HostCartPriceSummaryRow } from '@/components/atomic/molecules';
import { Text } from '@/components/atomic/nuclei';

const PriceSummaryOrganism = ({ priceSummaryList = [] }) => (
  <div>
    <Text
      {...{
        content: 'Price Details',
        className: 'text-sm md:text-base font-medium'
      }}
    />
    {priceSummaryList.map(
      ({ show = true, ...summary }, index) =>
        show && (
          <HostCartPriceSummaryRow
            key={index}
            {...{ ...summary }}
          />
        )
    )}
  </div>
);

export default PriceSummaryOrganism;
