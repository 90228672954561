import { useState } from 'react';

import {
  RenderCartNotification,
  RenderQuoteSubmittedNotification
} from '@/components/atomic/atoms';
import { PDPProductImageSlider } from '@/components/atomic/molecules';
import {
  ProductPriceBoxOrganism,
  ProductSummaryDetailOrganism
} from '@/components/atomic/organism';
import { useIsMobile } from '@/helpers/screenResolutionHelper';
import { decreaseQuantity, increaseQuantity } from '@/services/product.service';

const ProductDetailPageTemplate = ({ product }) => {
  const [isMobile] = useIsMobile();

  const minBookable = product.minBookable || 1;
  const [quantity, setQuantity] = useState(minBookable);
  const [showAddedToCartAlert, setShowAddedToCartAlert] = useState(false);

  const [showQuoteRequestSubmittedAlert, setShowQuoteRequestSubmittedAlert] =
    useState(false);
  const [showQuantityRangeAlert, setShowQuantityRangeAlert] = useState(false);
  const [isQuantityInvalid, setIsQuantityInvalid] = useState(false);

  const [showMaxBookableAlert] = useState(false);
  const revertQuantityToMin = () => {
    setQuantity(minBookable);
    setShowQuantityRangeAlert(false);
  };

  const handleCloseAddedToCartAlertBox = (e) => {
    e.preventDefault();
    setShowAddedToCartAlert(false);
  };

  return (
    <div className='md:px-20 md:py-5'>
      <PDPProductImageSlider {...{ ...product }} />
      <div className='flex gap-10 my-6 align-baseline'>
        <ProductSummaryDetailOrganism
          {...{ product, showQuantityRangeAlert }}
        />
        <ProductPriceBoxOrganism
          {...{
            decreaseQuantity,
            increaseQuantity,
            isMobile,
            isQuantityInvalid,
            minBookable,
            product,
            quantity,
            revertQuantityToMin,
            setIsQuantityInvalid,
            setQuantity,
            setShowAddedToCartAlert,
            setShowQuantityRangeAlert,
            showMaxBookableAlert,
            showQuantityRangeAlert
          }}
        />
      </div>

      {showAddedToCartAlert && (
        <RenderCartNotification
          {...{
            alertMessage: 'Item added to cart',
            handleClose: handleCloseAddedToCartAlertBox
          }}
        />
      )}
      {showQuoteRequestSubmittedAlert && (
        <div className='md:absolute md:bottom-0 md:left-1 w-full md:px-6'>
          <RenderQuoteSubmittedNotification
            {...{
              alertMessage: 'Response submitted we will get back to you soon.',
              handleClose: setShowQuoteRequestSubmittedAlert
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ProductDetailPageTemplate;
