import { useEffect, useState } from 'react';

import {
  CustomProgressBar,
  PaymentFailureModalMobile
} from '@/components/atomic/atoms';
import {
  CheckoutEventContactInfoOrganism,
  CheckoutHeaderOrganism,
  MobileOrderSummaryOrganism
} from '@/components/atomic/organism';
import { filterItemsByCartItemGroupType } from '@/services/hostCart.service';

const CheckoutMobileLayoutTemplate = ({ defaultHostCartDetail, status }) => {
  const {
    cartItems: hostCartItems,
    derivedValues = {},
    externalNotes,
    promoCodeDiscountAmount
  } = defaultHostCartDetail || {};

  const NRFQListItems = filterItemsByCartItemGroupType({
    items: hostCartItems,
    type: 'CHECKOUT_READY'
  });
  const [showOrderSummary, setShowOrderSummary] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [stepReached, setStepReached] = useState(1);
  const [showPaymentFailed, setShowpaymentFailed] = useState(status);

  const handlePaymentFailed = async () => {
    setShowpaymentFailed('');
  };

  useEffect(() => {
    if (status) {
      setCurrentStep(2);
      setStepReached(2);
      setShowpaymentFailed(status);
    }
  }, [status]);

  return (
    <>
      <CheckoutHeaderOrganism
        heading='Checkout'
        checkoutParams={{
          currentStep,
          isCheckoutFlow: true,
          setCurrentStep,
          setShowpaymentFailed,
          setStepReached,
          stepReached
        }}
      />
      <CustomProgressBar
        currentStep={currentStep}
        lastStep={2}
        paymentStatus={showPaymentFailed}
        setCurrentStep={setCurrentStep}
        setStepReached={setStepReached}
        stepReached={stepReached}
      />
      <MobileOrderSummaryOrganism
        {...{
          derivedValues,
          externalNotes,
          orderItems: NRFQListItems,
          promoCodeDiscountAmount,
          setShowOrderSummary,
          showOrderSummary
        }}
      />
      <div className='p-5'>
        {currentStep === 1 && (
          <CheckoutEventContactInfoOrganism
            {...{
              currentStep,
              setCurrentStep,
              setStepReached,
              stepReached
            }}
          />
        )}
        {showPaymentFailed && (
          <PaymentFailureModalMobile {...{ handlePaymentFailed }} />
        )}
      </div>
    </>
  );
};

export default CheckoutMobileLayoutTemplate;
