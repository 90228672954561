import dynamic from 'next/dynamic';
import { Fragment } from 'react';

import {
  AboutSectionWeb,
  BecomePartnerWeb,
  FeaturedCategoriesWeb,
  TopBrandsWeb,
  TrendingServices,
  TrustedPartners
} from '@/components/atomic/molecules';
import {
  DefaultLayoutOrganism,
  EventsGlimpsesWebOrganism,
  HaflaBlogOrganism,
  HeaderOrganism,
  TrendingCollectionOrganism,
  WhyChooseHaflaOrganism
} from '@/components/atomic/organism';

const HowDoesItWork = dynamic(
  () => import('@/components/atomic/molecules/homePage/HowDoesItWork'),
  {
    ssr: false
  }
);

const isActive = false;

const WebHomeTemplate = ({
  bannerTranslation,
  blogPost,
  collections,
  parentCategories
}) => (
  <Fragment>
    <HeaderOrganism
      categories={parentCategories}
      homeScreen={true}
    />
    <DefaultLayoutOrganism
      paramData={{
        bannerTranslate: bannerTranslation,
        eventCategory: 'home',
        homeScreen: true,
        translate: bannerTranslation
      }}
    >
      <TopBrandsWeb />
      <TrendingServices />
      <FeaturedCategoriesWeb />
      <TrustedPartners />
      <EventsGlimpsesWebOrganism />
      <BecomePartnerWeb />
      <TrendingCollectionOrganism {...{ collectionList: collections }} />
      <WhyChooseHaflaOrganism />
      {isActive && <HowDoesItWork />}
      <HaflaBlogOrganism posts={blogPost} />
      <AboutSectionWeb />
    </DefaultLayoutOrganism>
  </Fragment>
);

export default WebHomeTemplate;
